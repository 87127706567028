interface RegionNames {
    area_name: string
}

interface invType {
    invType: string
}

const Regions: RegionNames[] = [
    {
        area_name: "na"
    },
    {
        area_name: "usa_ut"
    },
    {
        area_name: "usa_mo"
    },
    {
        area_name: "usa_va"
    },
    {
        area_name: "usa_ms"
    },
    {
        area_name: "usa_in"
    },
    {
        area_name: "washington"
    },
    {
        area_name: "ohio"
    },
    {
        area_name: "u.s."
    },
    {
        area_name: "new_york"
    },
    {
        area_name: "usa_nm"
    },
    {
        area_name: "usa_ky"
    },
    {
        area_name: "usa_pa"
    },
    {
        area_name: "usa_wy"
    },
    {
        area_name: "usa_al"
    },
    {
        area_name: "minnesota"
    },
    {
        area_name: "usa_ia"
    },
    {
        area_name: "usa_ar"
    },
    {
        area_name: "texas"
    },
    {
        area_name: "usa_or"
    },
    {
        area_name: "california"
    },
    {
        area_name: "usa_mi"
    },
    {
        area_name: "usa_md"
    },
    {
        area_name: "usa_la"
    },
    {
        area_name: "usa_il"
    },
    {
        area_name: "usa_tn"
    },
    {
        area_name: "usa_wv"
    },
    {
        area_name: "colorado"
    },
    {
        area_name: "usa_ok"
    },
    {
        area_name: "usa_ak"
    },
    {
        area_name: "usa_ne"
    },
    {
        area_name: "usa_mt"
    },
    {
        area_name: "usa_ks"
    },
]

const invTypes: invType[] = [
    {
        invType: "underground_change_in_working_gas"
    },
    {
        invType: "total_underground_storage"
    },
    {
        invType: "underground_storage_net_withdrawals"
    },
    {
        invType: "underground_storage_withdrawals"
    },
    {
        invType: "underground_storage_injections"
    },
    {
        invType: "underground_storage_base_gas"
    },
    {
        invType: "underground_storage_working_gas"
    }

]

export { Regions, invTypes };
