import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../scenes/dashboard';

const AppContent = () => {
  return (
    <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/database" element={<div>Database</div>} />
        <Route path="/users" element={<div>Users</div>} />
        <Route path="/settings" element={<div>Settings</div>} />
    </Routes>
  )
}

export default AppContent;