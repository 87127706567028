export const tokenDark ={
    primary: {
        // Blue
        100: "#d0ddef",
        200: "#a1bbde",
        300: "#7398ce",
        400: "#4476bd",
        500: "#1554ad",
        600: "#11438a",
        700: "#0d3268",
        800: "#082245",
        900: "#111a2c"
    },
    grey: {
        // shade
        0: "#ffffff",
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
        1000: "#000000",
    },
}

interface Token {
    [key: string]: any; // Define the structure of your tokens
  }
  
interface ReversedTokens {
[key: string]: Token;
}
  
const reverseTokens = (token: Record<string, Token>): ReversedTokens => {
    const reversedTokens: ReversedTokens = {};
    Object.entries(token).forEach(([key, val]) => {
        const keys = Object.keys(val);
        const values = Object.values(val);
        const length = keys.length;
        const reversedObj: Token = {};
        for (let i = 0; i < length; i++) {
        reversedObj[keys[i]] = values[length - i - 1];
        }
        reversedTokens[key] = reversedObj;
    });

    return reversedTokens;
};

export const tokenLight: ReversedTokens = reverseTokens(tokenDark);
