import React, { useState } from 'react';
import './App.css';
import AppSider from './layout/AppSider';
import AppContent from './layout/AppContent';
import { ConfigProvider, Layout, theme } from 'antd';
import { tokenDark } from './theme';
const { Header, Content, Footer, Sider } = Layout;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <ConfigProvider
      theme = {{
        token:{
          colorPrimary: tokenDark.primary[500]
        },
        algorithm:theme.darkAlgorithm
      }}
    >
      <Layout style={{ height: '100vh' }}>
        <Header>
          Header
        </Header>
        <Layout style={{ height: '100vh' }}>
          <Sider 
            trigger={null} 
            collapsible collapsed={collapsed} 
            onCollapse={(value) => setCollapsed(value)}
            color='primary'
          >
            <AppSider />
          </Sider>
          <Layout>
            <Content>
              <AppContent />
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              Natural Gas Project ©{new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </Layout>

      {/* Without Layout from antd */}
      {/* <div style={{ display:'flex', flexDirection:'column', height:'100vh', width:'100vw' }}>
          <Header />
        <div style={{ display: 'flex',flexWrap: 'wrap', flexDirection:'row', height:'100vh' }}>
          <SideMenu />
          <div style={{ flex: 1 }}><Content /></div>
        </div>
        <Footer />
      </div> */}

    </ConfigProvider>
  );
}

export default App;
