/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useState} from 'react'
import {Regions} from '../interfaces/Region_names';
import {invTypes} from '../interfaces/Region_names';
import { Button, Card, message, Select } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';
import axios from 'axios';
import InnerHTML from 'dangerously-set-html-content'
import GraphData from '../components/graphData';

const RegionOptions = Regions.map((region, index) => {
  let modifiedName = region.area_name.replace('usa_', ''); // Remove leading underscore

  return {
      value: region.area_name,
      label: modifiedName.toUpperCase(),
  };
  
});

const InvTypeOptions = invTypes.map((inv, index) => {
    let modifiedName = inv.invType.replace(/_/g, ' '); // Remove all the underscores

    return {
        value: inv.invType,
        label: modifiedName,
    };
    
});


const Dashboard = () => {
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null); // State for selected region
  const [selectedInvType, setSelectedInvType] = useState<string | null>(null); // State for selected invType


  // Callback functions to handle selections in filters
  const handleRegionSelect = (value: string) => {
    setSelectedRegion(value); // Update selected region
  };

  const handleInvTypeSelect = (value: string) => {
    setSelectedInvType(value); // Update selected invType
  };

  return (
    <div className='Dashboard'>
      <Card 
        className='dashboard-filters'
      >
        <h3 className="dashboard-hint">Please select the filters below.</h3>
        <div className= "region-menu">
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select A Region"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options = {RegionOptions}
            onSelect={handleRegionSelect}
          />
        </div>
        <div className="invtype-menu">
          <Select
              showSearch
              style={{ width: 400 }}
              placeholder="Select A Type"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options = {InvTypeOptions}
              onSelect={handleInvTypeSelect}
          />
        </div>
      </Card>
      {GraphData(selectedRegion ?? '', selectedInvType ?? '')}

    </div>
  )
}

export default Dashboard;

