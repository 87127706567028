import React, {useState} from 'react';
import axios from 'axios';
import { message } from 'antd';
import { LineChartOutlined, DeleteOutlined } from '@ant-design/icons';
import {Button} from 'antd';
import InnerHTML from 'dangerously-set-html-content'
import './graphData.css';

const GraphData = (selectedRegion: string, selectedInvType: string) => {
    const [graphData, setGraphData] = useState<any>(null);

    const handleClearGraph = () => {
        setGraphData(null);
    }    

    const renderGraphImage = () => {
        if (graphData) {
    
          try {
            const imageDataUrl = `data:image/png;base64,${graphData}`;
            console.log('Image Data URL:', imageDataUrl);
            return <img src={imageDataUrl} alt="Graph" className='base64-image'/>;
          } catch (error) {
            console.error('Error decoding base64 data:', error);
          }
        } else {
          console.warn('No graphData to render.');
        }
      
        return null;
      };

    // Function to generate graph based on selected values
    const handleGenerateGraph = () => {
        if (selectedRegion && selectedInvType) {
            console.log('Generating graph based on filters:', selectedRegion,',',selectedInvType);
            axios({
            method: 'post',
            url: process.env.REACT_APP_ONLINE_SERVER + 'monthly-data/',  // online server
            // url: process.env.REACT_APP_LOCALHOST_SERVER + 'monthly-data/',  // localhost
            data: {
                region: selectedRegion,
                invtype: selectedInvType
            }
            })
            .then((response) => {
            console.log('Response:', response.data);

            handleClearGraph();
            setGraphData(response.data); // Update state with response data

            console.log(response.data);

            })
            .catch((error) => {
            console.error('Error fetching data:', error);
            setGraphData(null); // Reset graph data state in case of an error
            });
        } 
        else {
            console.log('Please select both filters');
            // TODO: add alert message
            message.warning('Please select both filters')
        }
    };

    return (
        <div className='dashboard-graph'>
            <Button
                icon = {<LineChartOutlined />}
                onClick = {handleGenerateGraph}
            >
                Generate A Graph!
            </Button>
            <Button danger
            icon = {<DeleteOutlined />}
            onClick = {handleClearGraph}
            >
                Clear
            </Button>

            <div className='generated-graph-container'>
                {graphData && (
                    <div className='generated-graph'>
                    <div className='dashboard-hint'>Result</div>
                    
                    {/* <div className='graph-container'>
                        <InnerHTML html={graphData} className='graph'/>
                    </div> */}

                    {renderGraphImage()}

                    </div>
                )}
            </div>
        </div>

    )

}


export default GraphData;